.cover-hero {
	.h-100 {
		.row {
			.col-sm-8 {
				h1 {
					color: #E7ECF0;
				}
				.content {
					color: #E7ECF0;
				}
			}
		}
	}
}

.navbar.background-color-primary {
	.nav-link {
		color: #02363E;
		&:hover, &:focus, &:active {
			color: #02363E;
		}
		&:visited {
			color: #02363E;
		}
	}
}

.navbar .btn-link {
	color: #02363E;
	background-color: $primary;

	&:hover, &:active, &:visited, &:focus {
		color: #02363E;
		background-color: $primary;
	}
}

.footer-primarybackgroundsection {
	color: #02363E;
}

a {
	color: #02363E !important;
}

i {
	color: #02363E !important;
}

.text-primary {
	color: #02363E !important;
}

.product-price {
	color: #02363E !important;
}

.product-image-selected {
	border-color: #02363E !important;
}

.symbols-section {
	color: #02363E !important;
}

.total-ads {
	color: #02363E !important;
}

.custom-slider {
	color: #02363E !important;
}

.search-btn {
	color: #02363E !important;
}

.btn-primary {
	color: #02363E !important;
}
